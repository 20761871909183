import { useState } from "react";

import SellContract from "@views/SellContract/SellContract";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import {
	getFilterDealershipsLocals,
	getFilterGestorias,
	getFilterProvinces
} from "@services/Filtros/filtros";
import { errorToast, successToast } from "@helpers/toastFunctions";
import {
	insouranceOptions,
	ivaTypeOptions,
	termOptions,
	transportDeliveryTypeOptions,
	transportSellTypeOptions,
	yesOrNotSelect
} from "@constants/selectOptions";
import BankOptions from "@constants/bankOptions";
import DocumentationType from "@constants/documentationType";
import {
	SELL_CONTRACT_DEFAULT_PRICES,
	SELL_CONTRACT_PRICE_TYPES
} from "@constants/sellContratDefaults";
import { isDisabledForExtraWarranty } from "@utils/isDisabledForExtraWarranty";

const EditSellContract = ({
	document: { datos: data, documentId, vehicle_id_stock },
	onEdit,
	vehicleYear,
	vehicleMonth
}) => {
	const [dealershipsOptions, setDealershipsOptions] = useState([]);
	const [gestoriasOptions, setGestoriasOptions] = useState([]);
	const [provincesOptions, setProvincesOptions] = useState([]);

	const notAllowedForExtraWarranty = isDisabledForExtraWarranty(
		vehicleYear,
		vehicleMonth,
		data.kilometros
	);

	const getPriceType = () => {
		if (data.priceType) return data.priceType;
		return data.buy_and_sell === 1
			? SELL_CONTRACT_PRICE_TYPES.PVD
			: SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO;
	};

	const loadDefaultValues = async () => {
		const [dealerships, gestorias, provinces] = await Promise.all([
			getFilterDealershipsLocals(),
			getFilterGestorias(),
			getFilterProvinces()
		]);

		setDealershipsOptions(dealerships);
		setGestoriasOptions(gestorias);
		setProvincesOptions(provinces);

		const findValue = (arr, findValue) => arr.find((x) => x.value === findValue);

		const clientDefault = {
			name: data.nombre,
			firstSurname: data.apellido1,
			secondSurname: data.apellido2,
			phone: data.telefono,
			email: data.email,
			dni: data.DNI,
			province: { label: data.provincia, value: data.id_provincia },
			town: data.poblacion,
			address: data.direccion,
			cp: data.CP,
			dateOfBirth: data.fecha_nacimiento
		};

		const vehicleDefault = {
			plate: data.matricula,
			km: data.kilometros,
			bastidor: data.bastidor,
			dealership: { label: data.local, value: data.id_local },
			dealershipProvince: { label: data.provincia_local, value: data.id_provincia_local },
			companie: data.empresa_nombre,
			iva: findValue(ivaTypeOptions, data.aplica_iva === 1 ? true : false),
			dateLastITV: data.fecha_ultima_itv,
			dateExpirationITV: data.fecha_caducidad_itv,
			conformidades: data.conformidades || null
		};

		const sellInfoDefault = {
			priceType: getPriceType().toString(),
			pvp: data.pvp,
			ownershipChangeAmount: data.coste_transferencia,
			gestoria: { label: data.gestoria, value: data.id_gestoria },
			financing: {
				wants: findValue(yesOrNotSelect, data.financia),
				discount:
					data.importe_financia === 0
						? data.pvp_contado
							? data.pvp_contado - data.pvp
							: 0
						: data.importe_financia,
				financialEntity: findValue(BankOptions, data.entidad_financiera),
				TIN: data.TIN || SELL_CONTRACT_DEFAULT_PRICES.TIN,
				term: findValue(termOptions, data.term),
				quota: data.quota || SELL_CONTRACT_DEFAULT_PRICES.QUOTA
			},
			transport: {
				sellType: transportSellTypeOptions.find((x) => x.value === data.tipo_venta),
				wants: findValue(yesOrNotSelect, data.transporte),
				delivery: {
					amount: data.importe_transporte,
					type: findValue(transportDeliveryTypeOptions, data.entrega),
					dealership:
						data.local_entrega && data.local_entrega_id
							? { label: data.local_entrega, value: data.local_entrega_id }
							: null,
					provinceDealership:
						data.provincia_local_entrega && data.provincia_local_entrega_id
							? { label: data.provincia_local_entrega, value: data.provincia_local_entrega_id }
							: null,
					useSameAddress: data.entrega_cambio,
					alternativeAddress: {
						province: provinces.find((x) => x.label === data.provincia_entrega),
						town: data.poblacion_entrega,
						address: data.direccion_entrega,
						CP: data.CP_entrega
					}
				}
			},
			warranty: {
				premium: {
					wants: findValue(yesOrNotSelect, data.garantia),
					amount: data.importe_garantia || SELL_CONTRACT_DEFAULT_PRICES.WARRANTY_PREMIUM_AMOUNT
				},

				extra: {
					wants:
						findValue(yesOrNotSelect, data.garantia_extra) ||
						(notAllowedForExtraWarranty && yesOrNotSelect[1]) ||
						undefined,
					amount: data.importe_garantia_extra || SELL_CONTRACT_DEFAULT_PRICES.WARRANTY_EXTRA_AMOUNT,
					IBAN: data.iban_garantia_extra
				}
			},
			insurance: {
				wants: findValue(yesOrNotSelect, data.seguro_auto),
				company: insouranceOptions.find((x) => x.value === data.aseguradora_auto),
				financing: findValue(yesOrNotSelect, data.financia_seguro),
				amount: data.importe_seguro || SELL_CONTRACT_DEFAULT_PRICES.INSURANCE_AMOUNT
			}
		};

		const payAmountsDefault = {
			financingAmount: data.pago_financiado,
			dealershipTPVAmount: data.pago_tpv_tienda,
			virtualTPVAmount: data.pago_tpv_pasarela,
			cashAmount: data.pago_efectivo,
			transferenceAmount: data.pago_transferencia,
			carPayAmount: data.pago_coche_forma_pago
		};

		const defaultValues = {
			client: clientDefault,
			vehicle: vehicleDefault,
			sellInfo: sellInfoDefault,
			payAmounts: payAmountsDefault,
			observations: data.observaciones
		};

		return defaultValues;
	};

	const editContract = async (formData) => {
		const clientData = {
			nombre: formData.client.name,
			apellido1: formData.client.firstSurname,
			apellido2: formData.client.secondSurname || null,
			telefono: formData.client.phone,
			email: formData.client.email,
			DNI: formData.client.dni,
			id_provincia: formData.client.province.value,
			provincia: formData.client.province.label,
			poblacion: formData.client.town,
			direccion: formData.client.address,
			CP: formData.client.cp,
			fecha_nacimiento: formData.client.dateOfBirth
		};

		const vehicleData = {
			matricula: formData.vehicle.plate,
			bastidor: formData.vehicle.bastidor,
			id_local: formData.vehicle.dealership.value,
			local: formData.vehicle.dealership.label,
			id_provincia_local: formData.vehicle.dealershipProvince.value,
			provincia_local: formData.vehicle.dealershipProvince.label,
			aplica_iva: formData.vehicle.iva.value,
			fecha_ultima_itv: formData.vehicle.dateLastITV,
			fecha_caducidad_itv: formData.vehicle.dateExpirationITV,
			conformidades: formData.vehicle.conformidades || null
		};

		const sellData = {
			priceType: formData.sellInfo.priceType,
			coste_transferencia: formData.sellInfo.ownershipChangeAmount,
			id_gestoria: formData.sellInfo.gestoria.value,
			gestoria: formData.sellInfo.gestoria.label,

			// Financing
			financia: formData.sellInfo.financing.wants.value,
			importe_financia: formData.sellInfo.financing.wants.value
				? formData.sellInfo.financing.discount
				: 0,
			entidad_financiera:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? formData.sellInfo.financing.financialEntity.value
					: null,
			meses_financia:
				formData.sellInfo.financing.wants.value && formData.sellInfo.financing.financialEntity
					? formData.sellInfo.financing.financialEntity.value === "Cetelem"
						? "18 meses"
						: "24 meses"
					: null,

			// Transport
			tipo_venta: formData.sellInfo.transport.sellType.value,
			entrega: formData.sellInfo.transport.delivery.type.value,
			transporte: formData.sellInfo.transport.wants.value,
			importe_transporte: formData.sellInfo.transport.delivery.amount,

			local_entrega_id:
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.value
					: null,
			local_entrega:
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.dealership.label
					: null,
			provincia_local_entrega_id:
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.value
					: null,
			provincia_local_entrega:
				formData.sellInfo.transport.delivery.type.value === transportDeliveryTypeOptions[0].value
					? formData.sellInfo.transport.delivery.provinceDealership.label
					: null,
			entrega_cambio: formData.sellInfo.transport?.delivery?.useSameAddress ?? true,

			// Warranty
			garantia: data.buy_and_sell ? false : formData.sellInfo.warranty.premium.wants.value,

			// Insurance
			seguro_auto: formData.sellInfo.insurance.wants.value
		};

		if (formData.sellInfo.financing.wants.value) {
			sellData.TIN = formData.sellInfo.financing?.TIN;
			sellData.term = formData.sellInfo.financing?.term?.value;
			sellData.quota = formData.sellInfo.financing?.quota;
		}

		// Direccion alternativa
		if (!sellData.entrega_cambio) {
			sellData.provincia_entrega =
				formData.sellInfo.transport.delivery.alternativeAddress.province.label;
			sellData.poblacion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.town;
			sellData.direccion_entrega = formData.sellInfo.transport.delivery.alternativeAddress.address;
			sellData.CP_entrega = formData.sellInfo.transport.delivery.alternativeAddress.CP;
		}

		if (formData.sellInfo.warranty.premium.wants?.value) {
			sellData.importe_garantia = formData.sellInfo.warranty.premium.amount;
			sellData.garantia_extra = formData.sellInfo.warranty.extra.wants.value;
			sellData.importe_garantia_extra = formData.sellInfo.warranty.extra.amount;
			sellData.iban_garantia_extra = formData.sellInfo.warranty.extra.IBAN;
		}

		if (formData.sellInfo.insurance.wants.value) {
			sellData.aseguradora_auto = formData.sellInfo.insurance?.company?.value;
			sellData.financia_seguro = formData.sellInfo.insurance?.financing?.value;
			sellData.importe_seguro = formData.sellInfo.insurance?.amount;
		}

		const payAmountData = {
			pago_financiado: formData.payAmounts.financingAmount,
			pago_tpv_tienda: formData.payAmounts.dealershipTPVAmount,
			pago_tpv_pasarela: formData.payAmounts.virtualTPVAmount,
			pago_efectivo: formData.payAmounts.cashAmount,
			pago_transferencia: formData.payAmounts.transferenceAmount,
			pago_coche_forma_pago: formData.payAmounts.carPayAmount
		};

		const datos = {
			...data,
			...clientData,
			...vehicleData,
			...sellData,
			...payAmountData,
			observaciones: formData.observations || null
		};

		const premiumValue = formData.sellInfo.warranty.premium.wants?.value;
		const wantsExtra = formData.sellInfo.warranty.extra.wants?.value;
		const warranty = wantsExtra ? 3 : premiumValue ? 2 : 1;

		try {
			await instance.post(API.CHANGE_GARANTIA, {
				warrantyTypeId: warranty,
				id: documentId
			});

			await instance.put(API.UPDATE_SALE_CONTRACT(documentId), {
				data: datos,
				typeContractId: DocumentationType.CONTRATO_VENTA
			});

			await onEdit();
			successToast("Contrato actualizado con éxito");
		} catch (err) {
			errorToast(err.response?.data?.message || "Error al actualizar el contrato.");
		}
	};

	return (
		<SellContract
			mode="edit"
			defaultValues={loadDefaultValues}
			onSubmit={editContract}
			gestoriasOptions={gestoriasOptions}
			provincesOptions={provincesOptions}
			dealershipsOptions={dealershipsOptions}
			isDisabledForExtraWarranty={notAllowedForExtraWarranty}
			flexicarVehicleId={vehicle_id_stock}
		/>
	);
};

export default EditSellContract;
