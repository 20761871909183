import { useEffect, useState } from "react";

import styles from "@styles/views/SellContract/index.module.scss";

import ClientData from "@views/SellContract/ClientData/ClientData";
import VehicleData from "@views/SellContract/VehicleData/VehicleData";
import SellData from "@views/SellContract/SellData/SellData";
import SellResume from "@views/SellContract/SellResume/SellResume";
import Observations from "@views/SellContract/Observations/Observations";

import Form from "@components/Form/Form";
import { sellContractSchema } from "@schema/SellContract";
import { getStock } from "@services/Vehicle/vehicle.service";

const SellContract = ({
	mode,
	defaultValues,
	onSubmit,
	gestoriasOptions,
	provincesOptions,
	dealershipsOptions,
	isDisabledForExtraWarranty,
	flexicarVehicleId
}) => {
	const [areTotalAmountsValid, setAreTotalAmountsValid] = useState(false);
	const [prices, setPrices] = useState({
		buyAndSell: false,
		priceDealerSelling: 0,
		priceDealerSellingOffer: 0,
		priceCash: 0,
		priceFinanced: 0,
		priceOffer: 0
	});

	useEffect(() => {
		(async () => {
			const stockPrices = await getStock(flexicarVehicleId);

			setPrices({
				buyAndSell: stockPrices.buyAndSell,
				priceDealerSelling: stockPrices.priceDealerSelling,
				priceDealerSellingOffer: stockPrices.priceDealerSellingOffer,
				priceCash: stockPrices.priceCash,
				priceFinanced: stockPrices.priceFinanced,
				priceOffer: stockPrices.priceOffer
			});
		})();
	}, [flexicarVehicleId]);

	return (
		<Form
			onSubmit={onSubmit}
			defaultValues={defaultValues}
			schema={sellContractSchema}
		>
			<ClientData provincesOptions={provincesOptions} />
			<VehicleData dealershipsOptions={dealershipsOptions} />
			<SellData
				gestoriasOptions={gestoriasOptions}
				dealershipsOptions={dealershipsOptions}
				provincesOptions={provincesOptions}
				isDisabledForExtraWarranty={isDisabledForExtraWarranty}
				prices={prices}
			/>
			<SellResume
				areTotalAmountsValid={areTotalAmountsValid}
				setAreTotalAmountsValid={setAreTotalAmountsValid}
			/>
			<Observations />

			<button
				className={`btn btn-flexicar-orange ${styles.createContractButton}`}
				type="submit"
				disabled={!areTotalAmountsValid}
			>
				{
					{
						create: "Crear contrato",
						edit: "Guardar"
					}[mode]
				}
			</button>
		</Form>
	);
};

export default SellContract;
