import React, { forwardRef, useState } from "react";
import ReactSelect, { components } from "react-select";
import { MdCheck } from "react-icons/md";

import OverflowWrapper from "./OverflowWrapper";
import customStyles from "./styles";

export const MultiValueContainer = (props) => {
	return (
		<div
			data-targetid={props.data.value}
			{...props.innerProps}
		>
			{props.children}
		</div>
	);
};

export const ValueContainer = ({ children, ...props }) => {
	return (
		<components.ValueContainer {...props}>
			{props.hasValue ? <OverflowWrapper>{children}</OverflowWrapper> : children}
		</components.ValueContainer>
	);
};

export const Option = ({ label, isSelected, ...rest }) => (
	<components.Option {...rest}>
		<div className="d-flex justify-content-between align-items-center">
			{label}
			{isSelected && (
				<MdCheck
					size={24}
					color="#D34600"
				/>
			)}
		</div>
	</components.Option>
);

/**
 * @typedef {object} ExtraFormSelectProps
 * @property {Function} loadOptions - Function to load the options on focus
 * @property {boolean} reloadOptions - Reload options on focus
 */

/**
 * @typedef {import("react-select").Props & ExtraFormSelectProps} CustomSelectProps
 */

/**
 * @type React.ForwardRefRenderFunction<?, CustomSelectProps>
 */
const Select = forwardRef((props, ref) => {
	const [options, setOptions] = useState(undefined);
	const [isLoading, setIsLoading] = useState(false);

	const lazyLoadOptions = async () => {
		if ((options === undefined && props.loadOptions) || props.reloadOptions) {
			setIsLoading(true);
			setOptions(undefined);
			const opts = await props.loadOptions();
			setOptions(opts);
			setIsLoading(false);
		}
	};

	return (
		<ReactSelect
			ref={ref}
			isSearchable
			menuPlacement="auto"
			closeMenuOnSelect={!props.isMulti ?? true}
			hideSelectedOptions={false}
			maxMenuHeight={300}
			noOptionsMessage={() => "Sin opciones"}
			isClearable={props.isMulti ?? false}
			isLoading={isLoading}
			options={options}
			onMenuOpen={lazyLoadOptions}
			{...props}
			components={{
				...props.components,
				Option,
				...(props.isMulti && {
					MultiValueContainer,
					ValueContainer
				})
			}}
			styles={{
				...customStyles(),
				...props.styles
			}}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary: "#fe9800",
					primary25: "#ffc548"
				}
			})}
		/>
	);
});

export default Select;
