import { useState, useEffect, useContext } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  MdAccountCircle,
  MdEmail,
  MdLock,
  MdSend,
  MdSettingsPhone,
  MdToday,
  MdVpnKey,
} from "react-icons/md";

import Mainlayout from "@layouts/MainLayout";
import styles from "./index.module.scss";
import DatePicker from "@components/DatePicker";
import Select from "@components/Select";
import {
  getLocals,
  getEmployeeType,
  getBrands,
  getCompanies,
  getPersonInfo,
  createEmployee,
  createPerson,
  addLocaleForEmployee,
  addCommercialBrandsForEmployee,
  addCompaniesForEmployee,
  searchPersonsCall,
} from "@services/Employees/AddEmployeeService";
import { UserContext } from "@contexts/UserContext";
import { errorToast } from "@helpers/toastFunctions";

const AddEmployee = () => {
  const { user: id } = useContext(UserContext);
  const history = useHistory();

  const [localSelectOptions, setLocalSelectOptions] = useState([]);
  const [employeeTypeSelectOptions, setEmployeeTypeSelectOptions] = useState(
    []
  );
  const [brandSelectOptions, setBrandSelectOptions] = useState([]);
  const [companySelectOptions, setCompanySelectOptions] = useState([]);
  const [personsSelectOptions, setPersonsSelectOptions] = useState([]);

  const [localsSelected, setLocalsSelected] = useState([]);
  const [employeeTypesSelected, setEmployeeTypeSelected] = useState("");
  const [brandsSelected, setBrandsSelected] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);

  const [personSelected, setPersonSelected] = useState(null);
  const [searchPersonInput, setSearchPersonInput] = useState({
    nombre: "",
    apellido1: "",
    apellido2: "",
  });

  const [personInfo, setPersonInfo] = useState({
    nombre: "",
    apellido1: "",
    apellido2: "",
    dni: "",
    fecha_nacimiento: new Date(),
  });

  const [employeeData, setEmployeeData] = useState({
    email: "",
    password: "",
    telefono_1: "",
    telefono_2: "",
  });


  useEffect(() => {
    (async () => {
      const responseLocalSelectOptions = await getLocals(id);
      setLocalSelectOptions([
        { value: "all", label: "Seleccionar todos" },
        ...responseLocalSelectOptions.map((x) => ({
          value: x.id,
          label: x.nombre
        }))
      ]);
    })();
    (async () => {
      const responseEmployeeTypeSelectOptions = await getEmployeeType(id);
      setEmployeeTypeSelectOptions(
        responseEmployeeTypeSelectOptions.map((x) => ({
          value: x.id,
          label: x.nombre,
        }))
      );
    })();
    (async () => {
      const responseBrandSelectOptions = await getBrands();
      setBrandSelectOptions(
        responseBrandSelectOptions.map((x) => ({
          value: x.id,
          label: x.nombre,
        }))
      );
    })();
    (async () => {
      const responseCompanySelectOptions = await getCompanies();
      setCompanySelectOptions(
        responseCompanySelectOptions.map((x) => ({
          value: x.id,
          label: x.nombre,
        }))
      );
    })();
  }, [id]);

  const searchPersons = async () => {
    try {
      const person = await searchPersonsCall(searchPersonInput);
      setPersonsSelectOptions(
        person.map((person) => ({
          value: person.id,
          label: `${person.nombre} ${person.apellido1} ${person.apellido2}`,
        }))
      );
    } catch (e) {
      errorToast("Error al buscar a la persona");
    }
  };

  const fetchPerson = async (personId) => {
    try {
      const person = await getPersonInfo(personId);
      setPersonInfo({
        nombre: person.nombre,
        apellido1: person.apellido1,
        apellido2: person.apellido2,
        dni: person.dni,
        fecha_nacimiento: new Date(person.fecha_nacimiento),
      });
      setEmployeeData((prev) => ({
        ...prev,
        personId,
      }));
    } catch (e) {
      errorToast("Error al cargar los datos de la persona");
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      localsSelected.length === 0 ||
      !employeeTypesSelected ||
      brandsSelected.length === 0 ||
      companiesSelected.length === 0
    ) {
      errorToast("Debe seleccionar todos los campos");
      return;
    }

    const addEmployee = async (data) => {
      try {
        const { employeeId } = await createEmployee(data);
        if (employeeId) {
          await addLocaleForEmployee(employeeId, {
            localesId: localsSelected.map((x) => x.value),
          });

          await addCommercialBrandsForEmployee(employeeId, {
            commercialBrandsId: brandsSelected.map((x) => x.value),
          });

          await addCompaniesForEmployee(employeeId, {
            companiesId: companiesSelected.map((x) => x.value),
          });

          history.push(`/main/ver-empleado/${employeeId}`);
        }
      } catch (err) {
        errorToast(err.message);
      }
    };

    if (personSelected) {
      addEmployee({
        personId: personSelected.value,
        typeId: employeeTypesSelected.value,
        email: employeeData.email,
        tel1: employeeData.telefono_1,
        tel2: employeeData.telefono_2,
      });
    } else {
      const { personId } = await createPerson({
        name: personInfo.nombre,
        surname1: personInfo.apellido1,
        surname2: personInfo.apellido2,
        dni: personInfo.dni,
        birthDate: personInfo.fecha_nacimiento.toLocaleDateString("es-ES"),
      });
      if (personId) {
        addEmployee({
          personId: personId,
          typeId: employeeTypesSelected.value,
          email: employeeData.email,
          tel1: employeeData.telefono_1,
          tel2: employeeData.telefono_2,
        });
      } else {
        errorToast("Error al crear el empleado");
      }
    }
  };

  const selectAllLocalsOptions = (values) => {
    setLocalsSelected(values)
    if (values.some((option) => option.value === "all")) {
      setLocalsSelected(localSelectOptions.slice(1));
    }
    ;
  };

  return (
    <Mainlayout>
      <Mainlayout.Content contentTitle="AGREGAR EMPLEADO" full>
        <div className="d-flex justify-content-center">
          <Form
            className={`${styles.form} col-12 col-lg-10`}
            onSubmit={onSubmit}
          >
            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione el local al que pertenece*</Label>
                <Select
                  isMulti
                  placeholder="Seleccionar..."
                  options={localSelectOptions}
                  value={localsSelected}
                  onChange={selectAllLocalsOptions}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione el tipo de empleado*</Label>
                <Select
                  placeholder="Seleccionar..."
                  options={employeeTypeSelectOptions}
                  value={employeeTypesSelected}
                  onChange={(value) => setEmployeeTypeSelected(value)}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione una o varias Marcas Comerciales*</Label>
                <Select
                  isMulti
                  placeholder="Seleccionar..."
                  options={brandSelectOptions}
                  value={brandsSelected}
                  onChange={(values) => setBrandsSelected(values)}
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <Label>Seleccione una o varias Empresas*</Label>
                <Select
                  isMulti
                  placeholder="Seleccionar..."
                  options={companySelectOptions}
                  value={companiesSelected}
                  onChange={(values) => setCompaniesSelected(values)}
                />
              </FormGroup>
              <div className="d-flex flex-wrap mb-2 mt-1">
              </div>
            </FormGroup>
            <blockquote>Datos de acceso</blockquote>
            <FormGroup className="d-flex flex-row flex-wrap">
              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdEmail size={24} />
                  <Label>Email*</Label>
                </div>
                <Input
                  required
                  type="email"
                  placeholder="example@example.com"
                  value={employeeData.email}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      email: e.target.value,
                    })
                  }
                />
              </FormGroup>

              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdLock size={24} />
                  <Label>Password*</Label>
                </div>
                <Input
                  disabled
                  type="password"
                  placeholder="*********"
                  value={employeeData.password}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      password: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>Teléfono 1*</Label>
                </div>
                <Input
                  required
                  type="tel"
                  value={employeeData.telefono_1}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      telefono_1: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-6">
                <div className={styles.icon_label}>
                  <MdSettingsPhone size={24} />
                  <Label>Teléfono 2</Label>
                </div>
                <Input
                  type="tel"
                  value={employeeData.telefono_2}
                  onChange={(e) =>
                    setEmployeeData({
                      ...employeeData,
                      telefono_2: e.target.value,
                    })
                  }
                />
              </FormGroup>
            </FormGroup>

            <blockquote>Datos personales</blockquote>

            <div className={`${styles.info_panel} mb-4`}>
              <span>
                Selecciona una persona para agregar como empleado o rellena los
                datos para crear una persona nueva. OJO, solo puede existir una
                persona por DNI.
              </span>
            </div>

            <FormGroup className="col-12 d-flex flex-row flex-wrap">
              <Label className="col-12">Seleccionar una persona</Label>
              <div className="col-12 d-flex flex-wrap justify-content-between">
                <Input
                  placeholder="Nombre"
                  className="col-12 col-lg-3 "
                  value={searchPersonInput.nombre}
                  onChange={(e) => {
                    e.persist();
                    setSearchPersonInput((prevSearchPersonInput) => ({
                      ...prevSearchPersonInput,
                      nombre: e.target.value,
                    }));
                  }}
                />
                <Input
                  placeholder="Primer apellido"
                  className="col-12 col-lg-3 mt-lg-0 mt-2"
                  value={searchPersonInput.apellido1}
                  onChange={(e) => {
                    e.persist();
                    setSearchPersonInput((prevSearchPersonInput) => ({
                      ...prevSearchPersonInput,
                      apellido1: e.target.value,
                    }));
                  }}
                />
                <Input
                  placeholder="Segundo apellido"
                  className="col-12 col-lg-3 mt-lg-0 mt-2"
                  value={searchPersonInput.apellido2}
                  onChange={(e) => {
                    e.persist();
                    setSearchPersonInput((prevSearchPersonInput) => ({
                      ...prevSearchPersonInput,
                      apellido2: e.target.value,
                    }));
                  }}
                />
                <Button
                  outline
                  className="col-12 col-lg-2 orange-btn mt-lg-0 mt-2"
                  onClick={searchPersons}
                >
                  BUSCAR PERSONAS
                </Button>
              </div>
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center mt-4 mt-lg-2">
                <Select
                  placeholder="Seleccionar..."
                  className="col-12 col-lg-6 p-0"
                  options={personsSelectOptions}
                  value={personSelected}
                  onChange={(option) => {
                    setPersonSelected(option);
                    fetchPerson(option.value);
                  }}
                />
              </div>
            </FormGroup>

            <FormGroup className="d-flex flex-row flex-wrap col-12 mt-5">
              <FormGroup className="col-12 col-lg-4">
                <div className={styles.icon_label}>
                  <MdAccountCircle size={24} />
                  <Label>Nombre*</Label>
                </div>
                <Input
                  required
                  type="text"
                  value={personInfo.nombre}
                  onChange={(e) =>
                    setPersonInfo({
                      ...personInfo,
                      nombre: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-4">
                <Label>Primer Apellido*</Label>
                <Input
                  required
                  type="text"
                  value={personInfo.apellido1}
                  onChange={(e) =>
                    setPersonInfo({
                      ...personInfo,
                      apellido1: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-4">
                <Label>Segundo Apellido*</Label>
                <Input
                  required
                  type="text"
                  value={personInfo.apellido2}
                  onChange={(e) =>
                    setPersonInfo({
                      ...personInfo,
                      apellido2: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-3">
                <div className={styles.icon_label}>
                  <MdVpnKey size={24} />
                  <Label>DNI*</Label>
                </div>
                <Input
                  required
                  type="text"
                  value={personInfo.dni}
                  onChange={(e) =>
                    setPersonInfo({
                      ...personInfo,
                      dni: e.target.value,
                    })
                  }
                />
              </FormGroup>
              <FormGroup className="col-12 col-lg-5">
                <div className={styles.icon_label}>
                  <MdToday size={24} />
                  <Label>Fecha de nacimiento*</Label>
                </div>
                <DatePicker
                  date={personInfo.fecha_nacimiento}
                  setDate={(date) =>
                    setPersonInfo({ ...personInfo, fecha_nacimiento: date })
                  }
                  value={personInfo.fecha_nacimiento}
                />
              </FormGroup>
            </FormGroup>

            <div className="d-flex justify-content-end">
              <Button outline className="orange-btn" type="submit">
                <span>CREAR EMPLEADO</span>
                <MdSend className="ml-2 mb-1" size={24} />
              </Button>
            </div>
          </Form>
        </div>
      </Mainlayout.Content>
    </Mainlayout>
  );
};

export default AddEmployee;
