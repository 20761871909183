import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import isEqual from 'lodash.isequal';

import { Input, Label, Container, Row, Col } from "reactstrap";

import { errorToast, successToast } from "@helpers/toastFunctions";
import revision from "@helpers/revision";
import Equipment from "@components/EquipmentJato";
import styles from "./index.module.scss";
import moment from "moment";
import ExtrasCategory from "@views/VehicleValidation/components/ExtrasCategory";

import Gallery from "@views/VehicleValidation/components/Gallery";
import ModalPreview from "@views/VehicleValidation/components/ModalPreview";

import { getDealer } from "@services/Dealers/dealers.service";
import {
	createVehicle,
	getDraft,
	getVersionData,
	putSales,
	putVehicle,
	putExtras,
	putAdditionalInfo
} from "@services/Vehicle/vehicle.service";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalCommentpdf from "@components/ModalCommentpdf";
import thousandsFormat from "@helpers/thousandsFormat";
import numberFormat from "@helpers/numberFormat";

import AdditionalInfo from "@views/VehicleValidation/components/AdditionalInfo";
import ejsTemplateRender from "@helpers/ejsTemplateRender";
import PaneHeader from "@components/PaneHeader";
import TabPanel from "@components/TabPanel";
import VehicleValidationData from "@views/VehicleValidation/components/Datos/VehicleValidationData";
import VehicleValidationSales from "@views/VehicleValidation/components/Venta/VehicleValidationSales";
import { checkVehicleValidationRol } from "@constants/roles";
import { UserContext } from "@contexts/UserContext";
import useSearchParam from "@hooks/useSearchParam";

const VehicleValidation = () => {
	const history = useHistory();
	const { id } = useParams();
	const { t, ready } = useTranslation("vehicleValidation");
	const { rol } = useContext(UserContext);
	const { getSearchParam } = useSearchParam();
	const activeTab = getSearchParam("tab");

	const [modalPreview, setModalPreview] = useState(false);

	const togglePreview = () => setModalPreview(!modalPreview);
	let template;
	const reset = () => (template = document.getElementById("template").innerHTML = <div></div>);
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current
	});

	const VEHICLE_DEFAULT_VALUES = {
		marca_vehiculo: "",
		modelo: "",
		matriculacion: "",
		combustible: "",
		ccambios: "",
		carroceria: "",
		version: "",
		jato: true,
		id_jato: null,
		modify: false,
		fecha_matriculacion: "",
		matriculacion_date: "",
		extras: [],
		color: [],
		local: [],
		procedencia: [],
		ecoSticker: [],
		resultado_itv: [],
		garantia: [],
		importe_garantia: 500,
		importe_transporte: 290,
		importe_trasnferencia: 290,
		interes_min: 9.99,
		meses_min: 72,
		aportacion_maxi: 10,
		vto_itv: null
	}

	const [vehiculo, setVehiculo] = useState(VEHICLE_DEFAULT_VALUES);
	const [defaultVehicle, setDefaultVehicle] = useState(VEHICLE_DEFAULT_VALUES);

	const [showButtons, setShowButtons] = useState(null);

	// const [manual, setManual] = useState(false);
	const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
	const [localInfo, setLocalInfo] = useState();
	const [localId, setLocalId] = useState(null);
	const [equipment, setEquipment] = useState([]);
	const [extras, setExtras] = useState([]);
	const [extrasOld, setExtrasOld] = useState([]);
	const [extrasSelected, setExtrasSelected] = useState([]);
	const [type, setType] = useState();
	const [impresion, setImpresion] = useState(false);
	const [additionalInfo, setAdditionalInfo] = useState({});

	const [documents, setDocuments] = useState([]);

	const [errors, setErrors] = useState({});
	const [basicsOk, setBasicsOk] = useState(false);

	const [retailPrice, setRetailPrice] = useState(null);
	const [price, setPrice] = useState(null);

	const launchQuery = useCallback(
		async (id, type) => {
			try {
				const response = await getDraft(id, type);
				const specs = response?.specs;

				const _vehicle = {
					marca_vehiculo: [{ label: response?.make.name, value: response?.make.id }],
					modelo: [{ label: response?.model.name, value: response?.model.id }],
					matriculacion: [{ label: response?.year, value: response?.year }],
					combustible: [{ label: response?.fuel.name, value: response?.fuel.id }],
					ccambios: [
						{
							label: response?.transmission.name,
							value: response?.transmission.id
						}
					],
					carroceria: [{ label: response?.bodyType.name, value: response?.bodyType.id }],
					version: [
						{
							label:
								response?.mode === "JATO"
									? response?.version.name.concat(
										" - ",
										specs?.doors,
										" ",
										"P",
										" ",
										"(",
										response?.modelYear,
										")"
									)
									: response?.version.name,
							value: response?.version.id
						}
					],
					jato: response?.mode === "JATO" ? true : false,
					id: response?.id,
					matricula: response?.plate,
					bastidor: response?.chassisNumber,
					cilindrada: specs?.cc,
					potencia: specs?.power,
					emisiones: specs?.emissions,
					maletero: specs?.cubinVolume,
					consumo_combi: specs?.consumptionMix,
					consumo_road: specs?.consumptionRoad,
					consumo_urban: specs?.consumptionUrban,
					asientos: specs?.seats,
					puertas: specs?.doors,
					km: response?.mileage,
					local: [
						{
							value: response?.dealership.id,
							label: response?.dealership.name
						}
					],
					precio_compra: response?.priceBought,
					procedencia: [{ label: response?.origin.name, value: response?.origin.id }],
					pvp_contado: response?.priceCash,
					pvp_financiado: response?.priceFinanced,
					oferta_particulares: response?.priceOffer ? true : false,
					precio_oferta_particulares: response?.priceOffer,
					libre_cargas: response?.chargeFree ? true : false,
					iva_deducible: response?.taxDeductible ? true : false,
					compraventas: response?.buyAndSell ? true : false,
					pvd: response?.priceDealerSelling,
					oferta_compraventas: response?.priceDealerSellingOffer ? true : false,
					precio_oferta_compraventas: response?.priceDealerSellingOffer,
					gestion_venta: !response?.owned ? true : false,
					penalizacion: response?.trading?.penalty,
					comision_venta: response?.trading?.commission,
					precio_estimado: response?.trading?.estimatedPrice,
					ecoSticker: [{ label: specs?.ecosticker?.name, value: specs?.ecosticker?.id }],
					importado: response?.imported,
					km0: response?.km0,
					fecha_matriculacion: new Date(response?.registrationDate),
					marchas: specs?.gearCount,
					km_correa_cambiada: response?.cambeltReplaceKm,
					upropietario: response?.uOwner,
					km_revision: response?.inspectionKm,
					ultima_revision: response?.lastInspectionDate
						? new Date(response?.lastInspectionDate)
						: undefined,
					ultima_itv: response?.itvLastDate ? new Date(response?.itvLastDate) : undefined,
					vto_itv: response?.itvDueDate ? new Date(response?.itvDueDate) : undefined,
					resultado_itv: [{ value: response?.itvResult }],
					garantia: response?.warrantyDate ? [{ value: 3 }] : [{ value: 0 }],
					fecha_garantia: response?.warrantyDate ? new Date(response?.warrantyDate) : undefined,
					color: [{ label: response?.color?.name, value: response?.color?.id }]
				}

				if (response) {
					setVehiculo((prev) => ({
						...prev,
						..._vehicle
					}));

					const equipment = response?.equipment;
					setEquipment(equipment);
					setExtras(response?.extras);
					setImagesPreviewUrls(response?.images);
					setBasicsOk(true);
					setAdditionalInfo({
						comment: response?.comment && response?.comment.text,
						lastInspectionDate:
							response?.lastInspectionDate && new Date(response?.lastInspectionDate),
						inspectionKm: response?.inspectionKm,
						cambeltReplaceKm: response?.cambeltReplaceKm,
						cambeltReplace: response?.cambeltReplaceKm ? true : false
					});
					setDocuments(response.documents);

					setLocalId(response?.dealership.id);

					setDefaultVehicle((prev) => ({
						...prev,
						..._vehicle
					}));

				}
			} catch (e) {
				errorToast(<span>{t("errors.errorPermission")}</span>);
				if (type === "draft") {
					history.push(`/main/validacion-vehiculo`);
				}
				if (type === "stock") {
					history.push(`/main/stock`);
				}
			}
		},
		[history, t]
	);

	const getVersions = async () => {
		try {
			const response = await getVersionData({
				version: vehiculo?.version[0]?.value,
				mode: "JATO"
			});
			const specs = await response?.specs;
			if (response) {
				setVehiculo({
					...vehiculo,
					cilindrada: specs?.cc ? specs?.cc : "",
					potencia: specs?.power ? specs?.power : "",
					emisiones: specs?.emissions ? specs?.emissions : "",
					maletero: specs?.cubinVolume ? specs?.cubinVolume : "",
					consumo_combi: specs?.consumptionMix ? specs?.consumptionMix : "",
					consumo_road: specs?.consumptionRoad ? specs?.consumptionRoad : "",
					consumo_urban: specs?.consumptionUrban ? specs?.consumptionUrban : "",
					asientos: specs?.seats ? specs?.seats : "",
					puertas: specs?.doors ? specs?.doors : "",
					marchas: specs?.gearCount ? specs?.gearCount : "",
					ecoSticker: specs?.ecosticker
						? [{ label: specs?.ecosticker?.name, value: specs?.ecosticker?.id }]
						: [{ label: "", value: "" }],
					id_version: response?.id
				});

				const equipment = await response?.equipment;

				setEquipment(equipment);
				setExtras(response?.extras);
			}
		} catch (e) {
			errorToast(e);
		}
	};

	const sendData = (extras) => {
		setVehiculo({
			...vehiculo,
			extras
		});
	};

	const handleFilter = (value, type) => {
		setVehiculo({
			...vehiculo,
			[type]: [{ value: value[0].value, label: value[0].label }]
		});
	};

	const local_info = () => {
		getDealer(`/dealers`, localId)
			.then((dealer) => {
				setLocalInfo(dealer);
			})
			.catch((e) => {
				throw e;
			});
	};

	const revisionVehicle = () => {
		const notEmptyInputs = [
			"marca_vehiculo",
			"modelo",
			"version",
			"procedencia",
			"km",
			"ccambios",
			"fecha_matriculacion",
			"potencia",
			"puertas",
			"asientos"
		];
		const notZeroInputs = ["puertas", "asientos", "marchas"];
		let result = true;
		const errorsTotal = {};
		notZeroInputs.forEach((e) => {
			if (!revision(t, "zero", e, vehiculo[e])) {
				result = false;
				errorsTotal[e] = true;
			}
		});
		notEmptyInputs.forEach((e) => {
			if (!revision(t, "empty", e, vehiculo[e])) {
				result = false;
				errorsTotal[e] = true;
			}
		});
		if (vehiculo?.potencia && !revision(t, "potencia", "potencia", vehiculo.potencia)) {
			result = false;
			errorsTotal.potencia = true;
		}
		if (vehiculo?.cilindrada && !revision(t, "cilindrada", "cilindrada", vehiculo.cilindrada)) {
			result = false;
			errorsTotal.cilindrada = true;
		}
		setErrors({
			...errors,
			...errorsTotal
		});

		return result;
	};

	const revisionSales = () => {
		const notEmptyInputs = ["pvp_contado"];
		let result = true;
		const errorsTotal = {};

		notEmptyInputs.forEach((e) => {
			if (!revision(t, "empty", e, vehiculo[e])) {
				result = false;
				errorsTotal[e] = true;
			}
		});
		if (
			vehiculo?.oferta_particulares &&
			!revision(t, "empty", "precio_oferta_particulares", vehiculo?.precio_oferta_particulares)
		) {
			result = false;
			errorsTotal.precio_oferta_particulares = true;
		}
		if (vehiculo?.gestion_venta) {
			const notEmptyInputs = ["precio_estimado", "comision_venta", "penalizacion"];

			notEmptyInputs.forEach((e) => {
				if (!revision(t, "empty", e, vehiculo[e])) {
					result = false;
					errorsTotal[e] = true;
				}
			});
		}
		if (vehiculo?.gestion_venta) {
			const notEmptyInputs = ["precio_estimado", "comision_venta", "penalizacion"];

			notEmptyInputs.forEach((e) => {
				if (!revision(t, "empty", e, vehiculo[e])) {
					result = false;
					errorsTotal[e] = true;
				}
			});
		}
		if (vehiculo?.compraventas) {
			const notEmptyInputs = ["pvd"];

			notEmptyInputs.forEach((e) => {
				if (!revision(t, "empty", e, vehiculo[e])) {
					result = false;
					errorsTotal[e] = true;
				}
			});
			if (
				vehiculo?.oferta_compraventas &&
				!revision(t, "empty", "precio_oferta_compraventas", vehiculo?.precio_oferta_compraventas)
			) {
				result = false;
				errorsTotal.precio_oferta_compraventas = true;
			}
		}
		setErrors({
			...errors,
			...errorsTotal
		});

		return result;
	};

	const handleSimpleInput = (e, type) => {
		setVehiculo({ ...vehiculo, [type]: e.target.value });
		setErrors({ ...errors, [type]: false });
	};

	const handleNumberThousands = (e, type) => {
		let thousandsNumber = thousandsFormat(e.target.value);
		setVehiculo({ ...vehiculo, [type]: thousandsNumber });
		setErrors({ ...errors, [type]: false });
	};

	const handleAdditionalInfo = (e, type) => {
		setAdditionalInfo({ ...additionalInfo, [type]: e.target.value });
		setErrors({ ...errors, [type]: false });
	};

	const handleDocuments = (e) => {
		setDocuments([...documents, e]);
	};

	const handleDeleteDocument = (deletedDocumentId) => {
		setDocuments(documents.filter((doc) => doc.id !== deletedDocumentId));
	};

	const handleCheckCambeltReplace = () => {
		setAdditionalInfo({
			...additionalInfo,
			cambeltReplace: !additionalInfo.cambeltReplace,
			cambeltReplaceKm: null
		});
	};

	const saveVehicleData = async (publish) => {
		const revisionV = revisionVehicle();
		if (revisionV) {
			try {
				if (vehiculo.jato) {
					const id = vehiculo?.id;
					const dataVehicle = {
						originId: Number(vehiculo?.procedencia[0].value),
						mode: vehiculo.jato ? "JATO" : "MANUAL",
						versionId: vehiculo?.id_version
							? vehiculo?.id_version
							: Number(vehiculo?.version[0].value),
						transmissionId: Number(vehiculo?.ccambios[0].value),
						registrationDate: moment(vehiculo?.fecha_matriculacion).format("YYYY-MM-DD"),
						mileage: vehiculo?.km ? numberFormat(vehiculo?.km) : null,
						km0: vehiculo?.km0 ? true : false,
						uOwner: vehiculo?.upropietario ? true : false,
						warrantyDate: vehiculo?.fecha_garantia
							? moment(vehiculo?.fecha_garantia).format("YYYY-MM-DD")
							: null,
						colorId: vehiculo?.color[0]?.value,
						upholsteryColor: vehiculo?.tcolor,
						itvDueDate: vehiculo?.vto_itv ? moment(vehiculo?.vto_itv).format("YYYY-MM-DD") : null,
						itvLastDate: vehiculo?.ultima_itv
							? moment(vehiculo?.ultima_itv).format("YYYY-MM-DD")
							: null,
						itvResult: vehiculo?.resultado_itv[0]?.value
					};

					await putVehicle(id, dataVehicle, type);

					if (!publish) {
						successToast("Vehículo guardado correctamente");
					}
				} else {
					const id = vehiculo?.id;
					const dataVehicle = {
						originId: Number(vehiculo?.procedencia[0].value),
						mode: vehiculo.jato ? "JATO" : "MANUAL",
						versionName: vehiculo?.version[0]?.label,
						transmissionId: Number(vehiculo?.ccambios[0].value),
						modelId: vehiculo?.modelo[0]?.value,
						bodyTypeId: vehiculo?.carroceria[0]?.value,
						fuelId: vehiculo?.combustible[0]?.value,
						registrationDate: moment(vehiculo?.fecha_matriculacion).format("YYYY-MM-DD"),
						mileage: vehiculo?.km ? numberFormat(vehiculo?.km) : null,
						km0: vehiculo?.km0 ? true : false,
						uOwner: vehiculo?.upropietario ? true : false,
						warrantyDate: vehiculo?.fecha_garantia
							? moment(vehiculo?.fecha_garantia).format("YYYY-MM-DD")
							: null,
						colorId: vehiculo?.color[0]?.value,
						upholsteryColor: vehiculo?.tcolor,
						itvDueDate: vehiculo?.vto_itv ? moment(vehiculo?.vto_itv).format("YYYY-MM-DD") : null,
						itvLastDate: vehiculo?.ultima_itv
							? moment(vehiculo?.ultima_itv).format("YYYY-MM-DD")
							: null,
						itvResult: vehiculo?.resultado_itv[0]?.value,
						specs: {
							ecostickerId: vehiculo?.ecoSticker[0]?.value,
							cc: vehiculo?.cilindrada ? Number(vehiculo?.cilindrada) : null,
							power: Number(vehiculo?.potencia),
							emissions: vehiculo?.emisiones ? Number(vehiculo?.emisiones) : null,
							cubinVolume: vehiculo?.maletero ? Number(vehiculo?.maletero) : null,
							consumptionUrban: vehiculo?.consumo_urban ? Number(vehiculo?.consumo_urban) : null,
							consumptionMix: vehiculo?.consumo_combi ? Number(vehiculo?.consumo_combi) : null,
							consumptionRoad: vehiculo?.consumo_road ? Number(vehiculo?.consumo_road) : null,
							seats: Number(vehiculo?.asientos),
							doors: Number(vehiculo?.puertas),
							gearCount: vehiculo?.marchas ? Number(vehiculo?.marchas) : null
						}
					};

					await putVehicle(id, dataVehicle, type);
					successToast("Datos de vehículo guardados correctamente");
				}
			} catch (e) {
				errorToast(<span>{t("errors.errorSave")}</span>);
			}
		}
	};

	const saveSalesData = async (publish) => {
		const revisionS = revisionSales();
		if (revisionS) {
			try {
				if (vehiculo?.version[0]?.value) {
					const id = vehiculo?.id;
					const dataSales = {
						priceFinanced:
							numberFormat(vehiculo?.pvp_financiado) === 0
								? null
								: numberFormat(vehiculo?.pvp_financiado),
						priceCash:
							numberFormat(vehiculo?.pvp_contado) === 0
								? null
								: numberFormat(vehiculo?.pvp_contado),
						priceOffer:
							numberFormat(vehiculo?.precio_oferta_particulares) === 0
								? null
								: numberFormat(vehiculo?.precio_oferta_particulares),
						taxDeductible: vehiculo?.iva_deducible,
						chargeFree: vehiculo?.libre_cargas,
						buyAndSell: vehiculo?.compraventas,
						priceDealerSelling:
							numberFormat(vehiculo?.pvd) === 0 ? null : numberFormat(vehiculo?.pvd),
						priceDealerSellingOffer: vehiculo?.oferta_compraventas
							? numberFormat(vehiculo.precio_oferta_compraventas)
							: null,
						trading: {
							estimatedPrice:
								numberFormat(vehiculo?.precio_estimado) === 0
									? null
									: numberFormat(vehiculo?.precio_estimado),
							commission:
								numberFormat(vehiculo?.comision_venta) === 0
									? null
									: numberFormat(vehiculo?.comision_venta),
							penalty:
								numberFormat(vehiculo?.penalizacion) === 0
									? null
									: numberFormat(vehiculo?.penalizacion)
						}
					};
					await putSales(id, dataSales, type);
					if (!publish) {
						successToast("Datos de venta guardados correctamente");
						launchQuery(id, type);
					}
				}
			} catch (e) {
				errorToast(<span>{t("errors.errorSave")}</span>);
			}
		}
	};

	const saveExtras = async () => {
		try {
			await putExtras(vehiculo.id, extrasSelected, type);
			successToast("Extras guardados correctamente");
			setExtrasOld(extrasSelected);
		} catch (e) {
			errorToast(<span>{t("errors.errorSaveExtra")}</span>);
		}
	};

	const saveAdditionalInfo = async () => {
		if (additionalInfo.cambeltReplace && !additionalInfo.cambeltReplaceKm)
			return errorToast("Introduzca Km del coche al cambio de correa");

		try {
			const additionalData = {
				comment: additionalInfo?.comment,
				lastInspectionDate:
					additionalInfo?.lastInspectionDate &&
					moment(additionalInfo?.lastInspectionDate).format("YYYY-MM-DD"),
				inspectionKm: additionalInfo?.inspectionKm ? Number(additionalInfo?.inspectionKm) : null,
				cambeltReplaceKm: additionalInfo?.cambeltReplaceKm
					? Number(additionalInfo?.cambeltReplaceKm)
					: null
			};
			const { data } = await putAdditionalInfo(id, type, additionalData);

			successToast("Información Adicional guardada correctamente");
			return { data };
		} catch (error) {
			errorToast("Error al guardar Información Adicional");
			return { error };
		}
	};

	const saveData = async () => {
		switch (activeTab) {
			case "datos":
				await saveVehicleData();
				break;
			case "sale":
				await saveSalesData();
				break;
			case "extras":
				await saveExtras();
				break;
			case "infoadicional":
				await saveAdditionalInfo();
				break;
			default:
				break;
		}
	};

	const publishVehicle = async () => {
		try {
			await saveVehicleData("publish");
			await saveSalesData("publish");
			await createVehicle(vehiculo?.id);
			successToast("Vehículo publicado correctamente");
			history.push(`/main/validacion-vehiculo`);
		} catch (e) {
			errorToast(<span>{t("errors.errorPublish")}</span>);
		}
	};

	const gethtmltopdf = async () => {
		try {
			const dataTemplate = { vehiculo, localInfo, imagesPreviewUrls, type: "VehicleValidation" };

			template = await ejsTemplateRender(2, dataTemplate);

			handlePrint();
		} catch (e) {
			errorToast(<span>{t("errors.errorPrint")}</span>);
		}
	};

	useEffect(() => {
		document.title = "Flexicar | Validación de vehiculo";
		const path = window.location.pathname.split("/");
		if (path.includes("validacion-vehiculo")) {
			setType("draft");
		}
		if (path.includes("edicion-vehiculo")) {
			setType("stock");
		}
	}, []);

	useEffect(() => {
		if (ready && id && type) launchQuery(id, type);
	}, [ready, id, type, launchQuery]);

	useEffect(() => {
		if (vehiculo.jato && basicsOk) {
			if (!vehiculo.version) {
				setVehiculo({
					...vehiculo,
					cilindrada: "",
					potencia: "",
					emisiones: "",
					maletero: "",
					consumo_combi: "",
					consumo_road: "",
					consumo_urban: "",
					asientos: "",
					puertas: "",
					marchas: "",
					ecoSticker: [{ label: "", value: "" }]
				});
			} else {
				getVersions();
			}
		}
	}, [vehiculo?.version[0]?.value]);

	useEffect(() => {
		if (localId) {
			local_info();
		}
	}, [localId]);

	useEffect(() => {
		if (ready) {
			const hasAccess = checkVehicleValidationRol(rol);
			setShowButtons(hasAccess);

			if (!hasAccess) {
				history.push("/main/validacion-vehiculo");
				errorToast(<span>{t("errors.errorPermissionPage")}</span>);
			}
		}
	}, [ready, t, rol, history]);

	const onClickPdfViewer = () => {
		if (isEqual(vehiculo, defaultVehicle)) {
			setImpresion(true);
		} else {
			errorToast(<span>{t("errors.errorMustSaveChanges")}</span>);
		}
	};

	const buttons = showButtons && (
		<div className={`ms-auto ${styles.buttons}`}>
			{activeTab !== "fotos" && activeTab !== "equipamiento" ? (
				<button
					className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
					onClick={() => saveData()}
				>
					{t("save")}
				</button>
			) : null}

			{type === "draft" ? (
				<button
					className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
					onClick={() => togglePreview()}
				>
					{t("publishVehicle")}
				</button>
			) : (
				<button
					className={`btn btn-light btn-outline-dark ${styles.bgroup} btn-flexicar `}
					onClick={() => onClickPdfViewer()}
				>
					Descargar pdf
				</button>
			)}
		</div>
	);

	return (
		<>
			<div style={{ display: "none" }}>
				<div
					ref={componentRef}
					className="m-5"
					id="template"
				>
					{template}
				</div>
			</div>

			<div className={styles.container}>
				<PaneHeader title={type === "draft" ? `${t("validateVehicle")}` : `${t("editVehicle")}`} />
			</div>

			<Container>
				<Row className="">
					<Col
						xs="12"
						sm="6"
					>
						<Label className={"m-0"}>{t("licensePlate")}</Label>
						<Input
							disabled={true}
							placeholder={t("licensePlate")}
							defaultValue={vehiculo.matricula}
						/>
					</Col>

					<Col
						xs="12"
						sm="6"
					>
						<Label className={"m-0"}>{t("chassisNumber")}</Label>
						<Input
							disabled={true}
							placeholder={t("chassisNumber")}
							defaultValue={vehiculo.bastidor}
						/>
					</Col>
				</Row>

				<div style={{ marginTop: "40px" }}>
					<TabPanel
						id="vehicleValidation"
						searchParam="tab"
						tabs={[
							{
								headers: {
									tabName: t("data"),
									searchParamValue: "datos"
								},
								content: (
									<>
										{buttons}
										<VehicleValidationData
											vehiculo={vehiculo}
											setVehiculo={setVehiculo}
											type={type}
											errors={errors}
											handleSimpleInput={handleSimpleInput}
											handleNumberThousands={handleNumberThousands}
											handleFilter={handleFilter}
										/>
									</>
								)
							},
							{
								headers: {
									tabName: t("sale"),
									searchParamValue: "sale"
								},
								content: (
									<>
										{buttons}
										<VehicleValidationSales
											vehiculo={vehiculo}
											setVehiculo={setVehiculo}
											handleSimpleInput={handleSimpleInput}
											handleNumberThousands={handleNumberThousands}
										/>
									</>
								)
							},
							{
								headers: {
									tabName: t("photos"),
									searchParamValue: "photos"
								},
								content: (
									<>
										{buttons}
										<Gallery
											imagesPreviewUrls={imagesPreviewUrls}
											setImagesPreviewUrls={setImagesPreviewUrls}
											vehicle_id={vehiculo?.id}
											type={type}
										/>
									</>
								)
							},
							{
								headers: {
									tabName: t("equipment"),
									searchParamValue: "equipment"
								},
								content: vehiculo.jato && (
									<>
										{buttons}
										<Equipment
											load={true}
											vehiculo={vehiculo}
											dontShowColapse={true}
											equipment={equipment}
											setEquipment={setEquipment}
										/>
									</>
								)
							},
							{
								headers: {
									tabName: t("extras"),
									searchParamValue: "extras"
								},
								content: vehiculo.jato && (
									<>
										{buttons}
										<ExtrasCategory
											extras={extras}
											setExtras={setExtras}
											state={{ checkLead: { extras: vehiculo.extras } }}
											setState={{ setVehiculo }}
											load={true}
											vehiculo={vehiculo}
											setVehiculo={setVehiculo}
											dontShowColapse={true}
											sendData={sendData}
											setRetailPrice={setRetailPrice}
											retailPrice={retailPrice}
											price={price}
											setPrice={setPrice}
											extrasSelected={extrasSelected}
											setExtrasSelected={setExtrasSelected}
											extrasOld={extrasOld}
											saveExtras={saveExtras}
										/>
									</>
								)
							},
							{
								headers: {
									tabName: t("additionalInfo"),
									searchParamValue: "additionalInfo"
								},
								content: (
									<>
										{buttons}
										<AdditionalInfo
											errors={errors}
											handleAdditionalInfo={handleAdditionalInfo}
											handleDocuments={handleDocuments}
											handleDeleteDocument={handleDeleteDocument}
											handleCheckCambeltReplace={handleCheckCambeltReplace}
											documents={documents}
											additionalInfo={additionalInfo}
											saveAdditionalInfo={saveAdditionalInfo}
											type={type}
											id={id}
										/>
									</>
								)
							}
						]}
					/>
				</div>
			</Container>

			<ModalPreview
				imagePrincipal={imagesPreviewUrls[0]}
				modalPreview={modalPreview}
				handleSimpleInput={handleSimpleInput}
				togglePreview={togglePreview}
				vehiculo={vehiculo}
				setImpresion={setImpresion}
			/>

			<ModalCommentpdf
				vehiculo={vehiculo}
				handleSimpleInput={handleSimpleInput}
				gethtmltopdf={gethtmltopdf}
				reset={reset}
				publishVehicle={publishVehicle}
				impresion={impresion}
				setImpresion={setImpresion}
				type={type}
			/>
		</>
	);
};

export default VehicleValidation;
